<template>
  <div class="record">
    <div class="title">{{ $t('链游报表') }}</div>

    <div class="record-card">
      <div class="table-key row">
        <!-- <div class="col">{{ $t('区块') }}</div> -->
        <div class="col">{{ $t('哈希') }}</div>
        <div class="col">{{ $t('哈希成绩') }}</div>
        <div class="col">{{ $t('时间') }}</div>
      </div>
      <div class="table-value row" v-for="item in list" :key="item.id">
        <!-- <div class="col">{{ item.blockNumber > 0 ? item.blockNumber : '-' }}</div> -->
        <div class="col">{{ item.blockHash ? abbr(item.blockHash, 2, 4) : '-' }}</div>
        <div class="col" style="color: #2FCD0F;">{{ item.resultValueNumber ? $t('成绩') + ' | ' + item.resultValueNumber : '-' }}</div>
        <div class="col">{{ item.resultTime ? item.resultTime : '-' }}</div>
      </div>
      <div class="row justify-center q-mt-md">
        <q-pagination v-model="current" :max="10" :max-pages="5" direction-links color="purple-9" @update:model-value="chang" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  props: {
    list: {
      type: Array,
      default() {
        return []
      }
    }
  },
  setup() {
    return {
      current: ref(1)
    }
  },
  methods: {
    abbr(val, start, end) {
      if (val && val.length > 20) {
        let newVal = val.replace(
          val.substring(start, val.length - end),
          "****"
        )
        return newVal
      } else {
        return val
      }
    },
    chang(e) {
      this.$emit('pageUp', e)
    }
  }
}
</script>

<style scoped>
.record {
  margin-top: 8px;
  padding-top: 30px;
  background-image: url(~@/assets/img/base/ranking-round.png);
  background-size: 100% auto;
  background-position: top;
  background-repeat: no-repeat;
}

.title {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  color: #fff;
}

.record-card {
  margin-top: 10px;
  padding: 15px;
  border-radius: 20px;
  background-color: #121C2E;
}

.table-key {
  height: 25px;
  display: flex;
  align-items: center;
  color: #4E5F7E;
  background-color: #081121;
  border-radius: 10px 10px 0 0;
  font-size: 12px;
  text-align: center;
}

.table-value {
  margin-top: 21px;
  display: flex;
  color: #fff;
  text-align: center;
  font-size: 13px;
}
</style>