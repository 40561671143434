<template>
  <div>
    <div class="form-card">
      <!-- 大小 单双 -->
      <div v-if="modeType != 3">
        <div class="form-label">{{ $t('每日获胜链游') }}</div>
        <div class="tabs">
          <div class="type-item" :class="{'type-active': type == item.value}" v-for="item in typeList" :key="item.value" @click="type = item.value">
            {{ item.text }}
          </div>
        </div>
      </div>
      <!-- 数字 -->
      <div v-if="modeType == 3">
        <div class="form-label">{{ $t('每日获胜链游') }}</div>
        <div class="tabs">
          <div class="type-item number-item" :class="{'type-active': xiazhuValue == index}" v-for="(item, index) in 10" :key="item" @click="xiazhuValue = index">
            {{ index }}
          </div>
        </div>
      </div>
      <div class="form-label q-mt-sm">{{ $t('链游模式') }}</div>
      <div class="tabs" style="justify-content: flex-start;">
        <div class="mode-item" :class="{'mode-active': mode == item.value}" v-for="item in modeList" :key="item.value" @click="modeClick(item.value)">
          {{ $t(item.text) }}
        </div>
      </div>
      <div class="form-label q-mt-sm">{{ $t('数额') }}</div>
      <div class="input-wrap">
        <img src="@/assets/img/base/form-jian.png" width="30" @click="minus" />
        <div class="col q-px-md">
          <q-input borderless v-model="jine" :placeholder="$t('请输入您的金额')" dense :input-style="{color: '#fff', textAlign: 'center'}" />
        </div>
        <img src="@/assets/img/base/form-jia.png" width="30" @click="jine++" />
      </div>
      <div class="balance">{{ $t('可用余额') }}：{{ balance }}</div>
      <div class="btn" @click="submit()">{{ $t('确认') }}</div>
      <div class="link-text"><span @click="tolink('/JingcaiRecord')">{{ $t('链游记录') }}</span></div>
    </div>

    <!-- 一键竞投 -->
    <q-dialog v-model="showOnekey" @hide="jine = ''">
      <div class="dialog-card">
        <div class="dialog-title">{{ $t('一键链游') }}</div>
        <div class="dialog-form">
          <div class="form-label">{{ $t('数额') }}</div>
          <div class="input-wrap q-mt-sm">
            <div class="col">
              <q-input borderless v-model="jine" dense :input-style="{color: '#fff', textAlign: 'center'}" readonly />
            </div>
          </div>
          <div class="amout-list">
            <div class="amout-item" :class="{'amout-active': jine == item}" v-for="(item, index) in xiazhuAmounts" :key="index"
              @click="jine = item">
              {{ item }}
            </div>
          </div>
          <div class="btn" @click="submit(true)">{{ $t('确认') }}</div>
        </div>
      </div>
    </q-dialog>
  </div>
</template>

<script>
import { ref } from 'vue'
import CustomComponent from "@/components/CustomComponent";

export default {
  props: {
    modeType: {
      type: [String, Number],
      default: 1
    },
    xiazhuAmounts: {
      type: Array,
      default() {
        return []
      }
    },
    balance: {
      type: [String, Number],
      default: "0"
    }
  },
  setup() {
    return {
      type: ref(""),
      typeList: ref([]),

      mode: ref(0),
      modeList: ref([
        {text: '链游', value: 0},
        {text: '一键链游', value: 1}
      ]),

      showOnekey: ref(false),
      jine: ref(""),
      xiazhuValue: ref("")
    }
  },
  mounted() {
    if(this.modeType == 1) {
      this.typeList = [
        {text: this.$i18n.t('大'), value: 1},
        {text: this.$i18n.t('小'), value: 0}
      ]
      this.type = this.typeList[0].value
    }
    if(this.modeType == 2) {
      this.typeList = [
        {text: this.$i18n.t('单'), value: 2},
        {text: this.$i18n.t('双'), value: 3}
      ]
      this.type = this.typeList[0].value
    }
    if(this.modeType == 3) {
      this.modeList = [{text: '链游', value: 0}]
      this.xiazhuValue = 0
      this.type = 4
    }
  },
  methods: {
    tolink(path) {
      this.$router.push(path)
    },
    minus() {
      if(this.jine > 0) {
        this.jine--
      }
    },
    // 模式点击
    modeClick(e) {
      if(e == 1) {
        this.showOnekey = true
        this.jine = this.xiazhuAmounts[0]
      }
    },
    submit(onekey = false) {
      if(this.jine == '') {
        this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["请输入链游金额"],
            persistent: true,
          }
        });
        return
      }
      let types = this.type
      if(onekey) {
        if(this.modeType == 1) types = '0,1'
        if(this.modeType == 2) types = '2,3'
      }
      this.$emit('confirm', {
        types: types,
        jine: this.jine,
        xiazhuValue: this.xiazhuValue,
        payWay: "POL Game", // 暂写死，如加支付方式再新增选择
      })
    }
  }
}
</script>

<style scoped>
.form-card {
  margin-top: 8px;
  padding: 15px;
  border: 1px solid #884DDA;
  box-shadow: 0px 0px 12.6px 0px #884DDA inset;
  border-radius: 20px;
  background-color: #121C2E;
}

.form-label {
  color: #4E5F7E;
  font-size: 12px;
}

.tabs {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.type-item {
  width: 49%;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: #fff;
  font-size: 15px;
  background-color: #000
}

.type-active {
  background: linear-gradient(180deg, #884DDA 0%, #482974 100%);
}

.mode-item {
  margin-right: 5px;
  width: 116px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 99px;
  border: 1px solid #000;
  box-sizing: border-box;
  background-color: #000;
  color: #fff;
}

.mode-active {
  color: #884DDA;
  border-color: #884DDA;
}

.input-wrap {
  margin-top: 5px;
  padding: 4px 5px;
  display: flex;
  align-items: center;
  border-radius: 99px;
  background: #000000;
}

.balance {
  margin-top: 10px;
  color: #4E5F7E;
  font-size: 12px;
}

.btn {
  margin-top: 40px;
  height: 48px;
  font-size: 15px;
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 99px;
  background-color: #D51B8C;
}

.dialog-card {
  padding: 20px 15px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #884DDA;
  box-shadow: 0px 0px 12.6px 0px #884DDA inset;
  background-color: #081121;
  border-radius: 20px;
}

.dialog-title {
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.dialog-form {
  margin-top: 20px;
}

.amout-list {
  display: flex;
  flex-wrap: wrap;
}

.amout-item {
  margin: 10px 5px 0 0;
  width: 68px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 99px;
  font-size: 12px;
  background-color: #000;
  color: #fff;
}

.amout-active {
  background: linear-gradient(180deg, #884DDA 0%, #482974 100%);
}

.number-item {
  width: 19%;
}

.number-item:nth-child(n + 6) {
  margin-top: 5px;
}

.link-text {
  margin-top: 20px;
  text-align: center;
  color: #4E5F7E;
  text-decoration: underline;
}
</style>