<template>
  <div>
    <headbar @confirm="setArticleId" />

    <div class="container">
      <div class="results">
        <img src="@/assets/img/base/jingcai-results.png" class="results-img" />
        <div class="results-content" v-if="latestResults">
          {{ abbr(latestResults.blockHash, 4, 4) }} <span class="m-color">({{ latestResults.resultValueNumber }})</span>
        </div>
        <div class="rule" @click="showRule = true">
          <img src="@/assets/img/base/jingcaiguize.png" width="13" />
          <div class="q-ml-xs">{{ $t('链游规则') }}</div>
        </div>
      </div>

      <div class="form">
        <!-- <div class="countdown">
          <div>{{ $t('倒数') }}</div>
          <van-count-down :time="countDown" format="ss" @finish="getList" />
        </div> -->
        <!-- 表单 -->
        <jingcai-form :modeType="modeType" :xiazhuAmounts="xiazhuAmounts" :balance="balance" @confirm="submit" />
      </div>

      <!-- 记录 -->
      <jingcai-all-record :list="list" :modeType="modeType" @pageUp="pageUp" />
    </div>

    <q-dialog v-model="showRule">
      <div class="rule-card">
        <div class="rule-title">{{ $t('规则') }}</div>
        <div v-html="articleData" class="rule-content"></div>
        <div class="rule-btn" @click="showRule = false">{{ $t('关闭') }}</div>
      </div>
    </q-dialog>

    <!-- txid -->
    <q-dialog v-model="showTxid" persistent>
      <q-card class="q-dialog-plugin" style="min-width: 350px">
        <q-card-section>
          <div class="text-body1">{{ $t('交易广播成功') }}</div>
          <div class="text-body2">Txid</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          <div class="input-wrap">
            <q-input borderless v-model="txid" dense :input-style="{ color: '#fff' }" style="width: 100%;" readonly>
              <template v-slot:append>
                <q-icon name="content_copy" style="font-size: 16px;" color="white" @click="copy(txid)" />
              </template>
            </q-input>
          </div>
        </q-card-section>
        <div style="padding: 0 16px 16px;">
          <div class="btn" style="margin: 0;" @click="showTxid = false">{{ $t('确定') }}</div>
        </div>
      </q-card>
    </q-dialog>

    <tab-bar active="2" />
  </div>
</template>

<script>
import { ref } from 'vue'
import JingcaiForm from './components/JingcaiForm'
import JingcaiAllRecord from './components/JingcaiAllRecord'
import CustomComponent from "@/components/CustomComponent";
import { CountDown } from 'vant';

import Web3Helper from "@/assets/js/web3helper.js";
import ExtractChineseWords from "@/assets/js/language/tool/extract-chinese-words.js";

export default {
  components: {
    JingcaiForm,
    JingcaiAllRecord,
    [CountDown.name]: CountDown
  },
  setup() {
    return {
      us: ref(null),
      modeType: ref(1), //玩法类型
      xiazhuAmounts: ref([]),
      balance: ref(0),
      list: ref([]),
      pageIndex: ref(1),

      latestResults: ref(null), // 最新竞猜结果，倒数第二条
      countDown: ref(0),

      showRule: ref(false),
      articleData: ref(""),
      articleId: ref(10),

      inAddress: ref(""),
      showTxid: ref(false),
      txid: ref(""),
      web3Helper: ref({})
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.modeType = this.$route.query.modeType
    if (this.us) {
      this.web3Helper = new Web3Helper();
      this.getSetting()
      this.getWallet()
      this.getList()
    }
    this.setArticleId()
  },
  methods: {
    // 翻页
    pageUp(e) {
      this.pageIndex = e
      this.getList()
    },
    abbr(val, start, end) {
      if (val && val.length > 20) {
        let newVal = val.replace(
          val.substring(start, val.length - end),
          "****"
        )
        return newVal
      } else {
        return val
      }
    },
    formatDateTime(dateTimeString) {
      const dateTime = new Date(dateTimeString);
      const month = String(dateTime.getMonth() + 1).padStart(2, '0');
      const day = String(dateTime.getDate()).padStart(2, '0');
      const hours = String(dateTime.getHours()).padStart(2, '0');
      const minutes = String(dateTime.getMinutes()).padStart(2, '0');
      return `${month}-${day} ${hours}:${minutes}`;
    },
    setArticleId() {
      let language = this.$utils.getloc("language")
      if(this.modeType == 1 && language == 'ch') this.articleId = 10
      if(this.modeType == 1 && language == 'en') this.articleId = 11
      if(this.modeType == 2 && language == 'ch') this.articleId = 12
      if(this.modeType == 2 && language == 'en') this.articleId = 13
      if(this.modeType == 3 && language == 'ch') this.articleId = 14
      if(this.modeType == 3 && language == 'en') this.articleId = 15
      this.getArticle()
    },
    getArticle() {
      let _this = this
      _this.$request.post(
        "api/Article/Get",
        {
          select_id: _this.articleId,
        },
        (res) => {
          _this.articleData = res.data.data.articlecontent;
        }
      )
    },
    getSetting() {
      let _this = this
      _this.$request.post(
        "api/JingcaiSetting/Get",
        {
          userid: _this.us.userid,
        },
        (res) => {
          if (res.data.code == 100) {
            let data = res.data.data
            _this.inAddress = data.address
            _this.xiazhuAmounts = data.xiazhuAmounts.split(',').filter(Boolean)
          }
        }
      )
    },
    getWallet() {
      let _this = this
      _this.$request.post(
        "api/Wallets/GetWalletsPageData",
        {
          userid: _this.us.userid
        },
        (res) => {
          if (res.data.code == 100) {
            let data = res.data.data
            _this.balance = data.find(item => item.cid == 2).jine
          }
        }
      )
    },
    getList() {
      let _this = this
      _this.loading = true
      _this.$request.post(
        "api/Jingcai/List",
        {
          pageIndex: _this.pageIndex,
          pageSize: 10,
          state: 99
        },
        (res) => {
          _this.loading = false
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
          }
          let data = res.data.data
          data.forEach(item => {
            if (item.state == 1) {
              let resultValueList = item.resultValue.split('|')
              if(_this.modeType == 3) {
                item.resultValueNumber = resultValueList[resultValueList.length - 1]
              } else {
                item.resultValueNumber = resultValueList[0]
              }
            }
            item.resultTime =  item.resultTime ? _this.formatDateTime(item.resultTime) : ''
          })
          // 最新结果, 取页码为1的
          if(_this.pageIndex == 1) {
            _this.latestResults = data.find(item => item.state == 1)
            // 倒计时
            let current = data.find(item => item.state == 0)
            if(current) {
              _this.countDown = new Date(current.endTime).getTime() - new Date(current.serverTime * 1000).getTime()
            }
          }
          _this.list = data
        }
      )
    },
    getResult() {
      let _this = this
      _this.$request.post(
        "api/JingcaiOrder/JingcaiResult",
        {
          userid: _this.us.userid,
          blockHash: _this.txid
        },
        (res) => {
          if(res.data.code == 100) {
            _this.getList()
          }
        }
      )
    },
    submit(e) {
      let _this = this
      this.$q.dialog({
        component: CustomComponent,
        componentProps: {
          messages: ["确定要链游吗?"],
          persistent: true,
          showCancel: true
        }
      }).onOk(() => {
        let web3Helper = new Web3Helper();
        this.$q.loading.show({
          message: _this.$i18n.t("链游下注中...")
        })
        web3Helper.getSign().then((signStr) => {
          _this.$request.post(
            "api/JingcaiOrder/Xiazhu",
            {

              userid: _this.us.userid,
              types: e.types,
              jine: e.jine,
              signStr,
              address: _this.us.address,
              payWay: e.payWay,
              xiazhuValue: e.xiazhuValue
            },
            (res) => {
              _this.$q.loading.hide();
              let msg = ""
              let msgList = ExtractChineseWords(res.data.msg)
              msgList.forEach(item => {
                msg += _this.$i18n.t(item)
              });
              _this.$q.dialog({
                component: CustomComponent,
                componentProps: {
                  messages: [msg],
                  persistent: true,
                }
              });
              if (res.data.code == 100) {
                _this.jine = ""
                _this.transfer()
              }
            }
          )
        }).catch(() => {
          _this.$q.loading.hide();
        })
      })
    },
    transfer() {
      let _this = this
      if (this.inAddress == '') {
        this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ['未设置转账指定地址'],
            persistent: true,
          }
        });
        return
      }
      let payWei = _this.web3Helper.toWei(0.01, 18);
      let outWei = 0;

      _this.$q.loading.show({
        message: _this.$i18n.t("正在交易中...")
      })

      _this.web3Helper.transfer(_this.us.address, _this.inAddress, payWei.toString()).then((txid) => {
        _this.$q.loading.hide();
        _this.txid = txid
        _this.showTxid = true
        _this.getResult()
        // _this.balance(_this.us.address)
      }, (err) => {
        _this.$q.loading.hide();
        //过滤4001,4001=拒绝签名
        if (err.code != 4001) {
          _this.$q.dialog({
            component: CustomComponent,
            componentProps: {
              title: err.code,
              messages: ['等待服务器验证交易'],
              persistent: true,
            }
          });
        }
      });
    },
  }
}
</script>

<style scoped>
.results {
  position: relative;
  text-align: center;
}

.results-img {
  width: 100%;
}

.container {
  padding: 0 15px;
}

.form {
  padding-top: 30px;
  background-image: url(~@/assets/img/base/ranking-round.png);
  background-size: 100% auto;
  background-position: top;
  background-repeat: no-repeat;
}

.countdown {
  margin: 0 auto;
  width: 295px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #fff;
  background: linear-gradient(180deg, #884DDA 0%, #482974 100%);
}

.results-content {
  position: absolute;
  top: 105px;
  left: 30px;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
}

.countdown :deep(.van-count-down) {
  margin-left: 20px;
  color: #fff;
  font-size: 25px;
  font-weight: bold;
}

.rule {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 22px;
  left: 30px;
  color: #fff;
  font-size: 12px;
}

.rule-card {
  width: 100%;
  padding: 20px 14px;
  border-radius: 20px;
  box-sizing: border-box;
  background: linear-gradient(180deg, #4E177F 0%, #1C1E4E 100%);
}

.rule-title {
  color: #fff;
  font-weight: bold;
}

.rule-content {
  margin-top: 20px;
  color: #884DDA;
  font-size: 12px;
  max-height: 400px;
  overflow-y: auto;
}

.rule-btn {
  margin-top: 40px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 99px;
  background-color: #D51B8C;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
}

.q-dialog-plugin {
  max-height: calc(100vh - 108px);
  background: #000;
  color: #fff;
  border: 2px solid #f56ef9;
}

.dialog-title {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em;
  padding: 16px;
}
</style>